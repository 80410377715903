import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loadingChats: [],
  unreadChats: [],
};

export const ChatSlice = createSlice({
  name: 'ChatSlice',
  initialState,
  reducers: {
    setLoadingChats: (state, action) => {
      state.loadingChats = action.payload;
    },
    setUnreadChats: (state, action) => {
      state.unreadChats = action.payload;
    },
  },
});

export const { setLoadingChats, setUnreadChats } = ChatSlice.actions;

export default ChatSlice.reducer;
