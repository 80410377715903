import { combineReducers, configureStore } from '@reduxjs/toolkit';
import SettingsReducer from './slices/SettingsSlice';
import AlertsReducer from './slices/AlertsSlice';
import MasterReducer from './slices/MasterSlice';
import UsersReducer from './slices/UsersSlice';
import ChatReducer from './slices/ChatSlice';

const rootReducer = combineReducers({
  settings: SettingsReducer,
  Alerts: AlertsReducer,
  Master: MasterReducer,
  User: UsersReducer,
  Chat: ChatReducer,
});

export const store = configureStore({
  reducer: rootReducer,
});
